<template>
  <ReportSimpleDataTable
    :urls="urls"
    :mandatory-filter="mendetoryFilters"
    :showTableIfClient="isClient"
  />
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ReportSimpleDataTable from "@/own/components/reports/ReportSimpleDataTable.vue";
export default {
  name: "ReportInventory",
  components: { ReportSimpleDataTable },
  data: () => ({
    urls: {
      data_url: "/reports/reportInventory",
      show_url: "/reports/reportInventory/show",
    },
  }),
  computed: {
    isClient: function () {
      return this.$store.getters.currentUser.data.is_client;
    },
    mendetoryFilters: function () {
      return this.isClient
        ? []
        : [
            {
              name: "client",
              title: "Seller",
              valueFieldName: "",
            },
          ];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
